import { GridColumn, Text, TextH2 } from 'components/box';
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton';
import Image from 'components/Image';
import UserAvatar from 'components/UserAvatar';
import useAccount from 'hooks/useAccount';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import React from 'react';
import addressParse from 'utils/addressParse';
import { ButtonPlatform, SideNavWrapper } from './styled';

function SideNav({ style }: { style?: React.CSSProperties }) {
  const account = useAccount();
  return (
    <SideNavWrapper style={style}>
      {account ? (
        <GridColumn className="account_container">
          <UserAvatar type={'fox'} />
          <Text>{addressParse(account, 4)}</Text>
          <Text>Your Wallet</Text>
          <ConnectWalletButton className="log_btn" />
        </GridColumn>
      ) : (
        <GridColumn className="account_container">
          <ConnectWalletButton className="log_btn" text="LOGIN" />
        </GridColumn>
      )}
      <GridColumn className="account_container">
        <ButtonPlatform active={true}>
          <Image className="img" src="image/logo/cat_face.svg" />
          <TextH2>COPYCAT</TextH2>
        </ButtonPlatform>
        <ButtonPlatform active={false}>
          <Image className="img" src="image/logo/2048.png" />
          <TextH2>2048</TextH2>
        </ButtonPlatform>
      </GridColumn>
    </SideNavWrapper>
  );
}

export default SideNav;
