import { FlexRow, LinkExternal, SocialButton, Text } from 'components/box';
import Image from 'components/Image';
import React, { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import Navbar from './Navbar';
import SideNav from './SideNav';
import { FooterWrapper, LayoutWrapper } from './styled';
import { animated, Transition } from 'react-spring';
import useResponsive from 'hooks/useResponsive';

const AnimedNavbar = animated(Navbar);
const AnimedSideNav = animated(SideNav);

function Footer() {
  return (
    <FooterWrapper>
      <FlexRow className="copyright">
        <Text size="1.2rem">2021 © copycat.finance</Text>
        <SocialButton image={'image/social/yellow.webp'}>
          <BsFillArrowUpCircleFill />
        </SocialButton>
        <FlexRow>
          <LinkExternal
            size={'1.2rem'}
            text="TERM & CONDITION"
            link={'https://www.google.com/'}
          />
          <Text style={{ margin: '0 1rem' }}>||</Text>
          <LinkExternal
            text="PRIVACY POLICY"
            link={'https://www.google.com/'}
          />
        </FlexRow>
      </FlexRow>
    </FooterWrapper>
  );
}

interface Props {
  children: React.ReactNode;
}
function Layout(props: Props) {
  const [popUpNavbar, setPopUpNavbar] = useState(false);
  const [popUpSide, setPopUpSide] = useState(false);
  const responsive = useResponsive('laptop');

  const setState = () => {
    setPopUpNavbar(false);
    setPopUpSide(false);
  };

  if (window.location.pathname == '/connect') {
    return <LayoutWrapper>{props.children}</LayoutWrapper>
  }

  return (
    <LayoutWrapper>
      {responsive ? (
        <Transition
          items={popUpNavbar || popUpSide}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          config={{ duration: 100 }}
        >
          {({ opacity, transform }, item) => {
            console.log(transform);
            if (item)
              return (
                <animated.div
                  style={{
                    opacity: opacity,
                  }}
                  className="bg_dark"
                  onClick={setState}
                />
              );
          }}
        </Transition>
      ) : null}
      {/* <animated.div className="bg_dark" /> */}
      <FlexRow className="menu_top_container">
        <div className="icon">
          <AiOutlineMenu
            onClick={() => {
              setPopUpNavbar(!popUpNavbar);
            }}
          />
        </div>
        <div
          className="icon2"
          onClick={() => {
            setPopUpSide(!popUpSide);
          }}
        >
          <Image src="/image/logo/logo.svg" alt="copycat" />
        </div>
      </FlexRow>
      <div className="content">
        {responsive ? (
          <Transition
            items={popUpNavbar}
            from={{ opacity: 0, transform: 'translate3d(-100%,0,0)' }}
            enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
            leave={{ opacity: 0, transform: 'translate3d(-100%,0,0)' }}
            config={{ duration: 300 }}
          >
            {({ opacity, transform }, item) => {
              console.log(transform);
              if (item)
                return (
                  <AnimedNavbar
                    setState={setState}
                    style={{
                      opacity: opacity,
                      transform: transform,
                    }}
                  />
                );
            }}
          </Transition>
        ) : (
          <Navbar setState={setState} />
        )}

        <div className="content_container">
          <div className="inside_border">{props.children}</div>
        </div>
        {responsive ? (
          <Transition
            items={popUpSide}
            from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
            enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
            leave={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
            config={{ duration: 300 }}
            delay={100}
          >
            {({ opacity, transform }, item) => {
              if (item)
                return (
                  <AnimedSideNav
                    style={{
                      opacity: opacity,
                      transform: transform,
                    }}
                  />
                );
            }}
          </Transition>
        ) : (
          <SideNav />
        )}
        <Footer />
      </div>
    </LayoutWrapper>
  );
}

export default Layout;
