import React from 'react';
import styled from 'styled-components/macro';

export const LayoutContentWrapper = styled.div<{
  noTopBg?: Boolean;
  width?: string;
}>`
  /* min-height: calc(100vh); */
  width: ${({ width }) => (width ? width : '100%')};
  margin: 0 auto;
  /* padding: 1.5rem 0; */
  /* padding: 2rem 0; */
  /* BG */
  /* background-image: url('image/breadcrumbs-bg.webp'); */
  /* background-size: 100vw 100vh; */
  background-repeat: no-repeat;
  /* padding-top: 10rem; */
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
}

function LayoutContent({ children, className, style, width }: Props) {
  return (
    <LayoutContentWrapper width={width} style={style} className={className}>
      {children}
    </LayoutContentWrapper>
  );
}

export default LayoutContent;
