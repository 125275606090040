import LayoutContent from 'components/Layout/LayoutContent';
import useAccount from 'hooks/useAccount';
import React, { useState } from 'react';
import ConnectWalletPopup from './ConnectWalletPopup';

export default function ConnectWalletPage() {
  const account = useAccount();
  const [loading, setLoading] = useState(false);

  return (
    <LayoutContent>
      {loading && <div>Loading</div>}

      <ConnectWalletPopup
        show={!loading}
        onClose={() => {
          window.close();
          window.history.back();
        }}
        onConnect={() => {
          setLoading(true)
        }}
      ></ConnectWalletPopup>
    </LayoutContent>
  )
}