export const ENV = {};

export const avatarUserPath: { [key: string]: string } = {
  blackCat: 'image/user/blackCat.svg',
  blackCat2: 'image/user/blackCat.svg',
  catRich: 'image/user/catRich.svg',
  catSwag: 'image/user/catSwag.svg',
  fox: 'image/user/fox.svg',
  oldCat: 'image/user/oldCat.svg',
  orangeCat: 'image/user/orangeCat.svg',
  pinkCat: 'image/user/pinkCat.svg',
  pinkCat2: 'image/user/pinkCat2.svg',
  whitecat: 'image/user/whitecat.svg',
};
