import { colors } from 'colors/colors';
import styled from 'styled-components/macro';
import { device, imageBg } from 'styling/fast';

export const WalletWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content;
  gap: 2rem;
  .token_container {
    padding: 0;
    border: solid 0.2rem ${colors.PURPLE_LIGHT};
    border-radius: 1rem;
    position: relative;
    .badge {
      width: 20rem;
      display: flex;
      color: white;
      background-image: url('/image/other/badge-purple.png');
      height: 6rem;
      text-shadow: rgb(165 255 249) 0px 0px 10px;
      align-items: center;
      padding-left: 3rem;
      ${imageBg}
      border-radius: 1rem 0 0 1rem;
      position: absolute;
      z-index: 0;
      top: -1rem;
      left: -1rem;
    }
    .content_token {
      position: relative;
      z-index: 1;
      h2 {
        margin-bottom: 1.5rem;
      }
    }
  }
  .nft_container {
    border: solid 0.2rem ${colors.BLUE};
    .badge {
      background-image: url('/image/other/badge-blue.png');
    }
  }
  .token_list {
    padding: 1rem 2rem;
    gap: 0.5rem;
  }
  .nft_list {
    padding: 1.5rem 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 24rem);
    gap: 2rem;
    @media ${device.mobileL} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const TokenListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 120px 120px;
  border-bottom: solid 0.2rem ${colors.PURPLE_BTN_LIGHT_0};
  padding-bottom: 0.5rem;
  align-items: center;
  gap: 1rem;
  h3 {
    font-size: 1.6rem;
  }
  .token_name {
    justify-content: flex-start;
    .token {
      margin-right: 1rem;
      height: 4rem;
      width: 4rem;
      background-color: white;
      border-radius: 100%;
    }
  }
  .btn_token {
    height: auto;
    width: auto;
    padding: 0.5rem 0;
    font-size: 1.6rem;
  }
  .deposit {
    background: ${colors.GLEEN_PROFIT};
  }
  .withdraw {
    background: ${colors.RED_PROFIT};
  }
`;
