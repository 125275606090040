import web3 from "hooks/web3";

export async function requestSignature(data: string) {
  const accounts = await web3.eth.getAccounts();
  // console.log(data, accounts[0]);
  if (accounts[0]) {
    return await web3.eth.personal.sign(data, accounts[0], "");
  }
  
  return "";
}