/* eslint-disable array-callback-return */
import { FlexColumn } from 'components/box';
import Image from 'components/Image';
import React from 'react';
import { IoGameController, IoWalletSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { LinkItemTEXT, NavbarWrapper } from './styled';
import useLocationQuery from 'hooks/useLocationQuery';
import { AiTwotoneTrophy } from 'react-icons/ai';
import { MdOutline6FtApart } from 'react-icons/md';

function LinkItem({ text, link, active, onClick, icon }) {
  return (
    <Link onClick={onClick} to={link}>
      <LinkItemTEXT active={active}>
        {icon}
        <h1>{text}</h1>
      </LinkItemTEXT>
    </Link>
  );
}

interface Props {
  style?: React.CSSProperties;
  setState: () => any;
}

function Navbar({ style, setState }: Props) {
  // const responsive = useResponsive('laptop');
  // const scroll = useScrollEvent(responsive ? 4 : 10);
  const [, page] = useLocationQuery();

  const verifyRoute = (e) => {
    let verify = false;
    if (!e && !page) {
      verify = true;
    } else if (e) {
      const n = e.find((i) => i === page);
      if (n) {
        verify = true;
      }
    }

    return verify;
  };

  const MenuList = () => (
    <>
      <LinkItem
        icon={<IoWalletSharp className="icon" />}
        onClick={() => setState()}
        active={verifyRoute('')}
        text="My Wallet"
        key=""
        link="/"
      />
      <LinkItem
        icon={<AiTwotoneTrophy className="icon" />}
        active={verifyRoute(['reward'])}
        text="NFT"
        key="NFT"
        link="/nft"
        onClick={() => setState()}
      />
      <LinkItem
        icon={<MdOutline6FtApart className="icon" />}
        active={verifyRoute(['scholarship'])}
        text="Scholarship"
        key="scholarship"
        link="/scholarship"
        onClick={() => setState()}
      />
      <LinkItem
        icon={<IoGameController className="icon" />}
        active={verifyRoute(['game'])}
        text="Game"
        key="game"
        link="/game"
        onClick={() => setState()}
      />
    </>
  );

  return (
    <NavbarWrapper style={style}>
      <FlexColumn className="header">
        <Image src="/image/logo/logo.svg" />
      </FlexColumn>
      <FlexColumn className="menu_contain">
        <MenuList />
      </FlexColumn>
    </NavbarWrapper>
  );
}

export default Navbar;
