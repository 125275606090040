import axios from "axios";
import jwt from "jsonwebtoken";
import web3 from "hooks/web3";
import { requestSignature } from "./signature";
import { pollingFn } from "./polling";

const SIGNATURE_TAIL = "\n\nThis is Copycat Wallet transaction. Don't sign this request if this request doesn't come from Copycat Wallet."

let ALREADY_LOGIN_SIGNATURE = false;

export const accessToken = window.location.pathname == "/connect" ? "" : window.localStorage.getItem('CPCWALLET_ACCESS_TOKEN');

let walletAddress = "";
let walletAddressPlayer = "";
let loginToWalletCallback;

export function buildApiWalletSignature(args): string {
  return `CPC:${args.join(':').toLowerCase()}:${SIGNATURE_TAIL}`;
}

export function getWalletApplicationId() {
  try {
    let data: any = jwt.decode(window.localStorage.getItem('CPCWALLET_ACCESS_TOKEN'));
    return data.appid || 1;
  } catch (err) {
    return 0;
  }
}

export async function loginToWallet(walletAddressPlayer, walletAddress) {
  let timestamp = Math.floor(Date.now() / 1000);
  let signature = await requestSignature(buildApiWalletSignature(["login", 1, timestamp]));

  const urlParams = new URLSearchParams(window.location.search);

  let walletApplicationId = 1;
  let ct = false;

  if (window.location.pathname == "/connect") {
    walletApplicationId = parseInt(urlParams.get('appid'))
    ct = true;

    if (!walletApplicationId) {
      window.alert("Unknown application");
      return;
    }
  }

  let response = await callApi({
    action: "token",
    args: {
      walletAddressPlayer,
      walletAddress,
      walletApplicationId,
      signature,
      signatureTimestamp: timestamp,
      ct,
    },
    anonymous: true,
  })

  console.log(response);

  if (loginToWalletCallback) {
    loginToWalletCallback(response, walletAddressPlayer, walletAddress);
  }

  return response;
}

export async function setLoginToWalletCallback(callback) {
  loginToWalletCallback = callback;
}

export function getWalletAddress(web3account: string): string | null {
  if (walletAddressPlayer) {
    if (web3account != walletAddressPlayer) {
      window.localStorage.removeItem('CPCWALLET_ACCESS_TOKEN');
      window.location.reload();
      return null;
    }
    return walletAddress;
  }

  if (accessToken) {
    const data: any = jwt.decode(accessToken);
    if (data.exp * 1000 + 600 * 1000 > Date.now()) {
      if (web3account == data.wp) {
        walletAddress = data.w;
        walletAddressPlayer = data.wp;
        return walletAddress;
      }
    }
  }

  if (web3account) {
    window.localStorage.removeItem('CPCWALLET_ACCESS_TOKEN');

    // if (!ALREADY_LOGIN_SIGNATURE) {
    //   ALREADY_LOGIN_SIGNATURE = true;
    //   loginToWallet(web3account, web3account).catch(err => {
    //     ALREADY_LOGIN_SIGNATURE = false;
    //   });
    // }
  }

  return null;
}

export function recoverTxWithdrawSignature(row) {
  let parts = row.signature.split('_');

  return {
    functionSignature: row.note,
    r: parts[2],
    s: parts[3],
    v: parseInt(parts[4]),
  }
}

export const api = axios.create({
  // baseURL: process.env.REACT_APP_API_HOST,
  headers: accessToken ? {
    Authorization: "Bearer " + accessToken,
  } : {}
})

export class UserRejectionError extends Error {
  constructor() {
    super("User rejected the transaction");
  }
}

// FINISH STATUS: 0=success 1=error 2=userrejected
export interface CallApiProps {
  action: string;
  args: any;
  before?: () => Promise<any>;
  polling?: (data: any) => Promise<boolean>;
  then?: (data: any) => Promise<any>;
  error?: (error: any) => Promise<any>;
  finish?: (status: number, data: any) => Promise<any>;
  customErrorHandler?: boolean;
  anonymous?: boolean;
}

export async function callApi({action, args, before, polling, then, error, finish, customErrorHandler = false, anonymous = false }: CallApiProps) {
  try {
    // Get wallet address
    const accounts = await web3.eth.getAccounts();
    const walletAddress = accounts[0] && getWalletAddress(accounts[0]);

    if (before) await before();

    // TODO: Hardcode signature request
    try {
      const timestamp = Math.floor(Date.now() / 1000)

      switch (action) {
        case 'txWithdraw':
          args.signature = await requestSignature(buildApiWalletSignature([
            'txWithdraw',
            walletAddress,
            args.tokenAddress,
            args.tokenId,
            args.amount,
            timestamp,
          ]));
          args.signatureTimestamp = timestamp;
          break;

        case 'txHarvestReward':
          args.signature = await requestSignature(buildApiWalletSignature([
            'txHarvestReward',
            walletAddress,
            args.tokenAddress,
            args.tokenId,
            timestamp,
          ]));
          args.signatureTimestamp = timestamp;
          break;

        case 'addWallet':
          args.signature = await requestSignature(buildApiWalletSignature([
            'addWallet',
            args.walletScholar,
            walletAddress,
            getWalletApplicationId(),
            args.scholarPercent,
            timestamp,
          ]));
          args.signatureTimestamp = timestamp;
          break;

        case 'addMaster':
          args.signature = await requestSignature(buildApiWalletSignature([
            'addMaster',
            args.walletScholar,
            walletAddress,
            getWalletApplicationId(),
            args.scholarPercent,
            timestamp,
          ]));
          args.signatureTimestamp = timestamp;
          break;
          
        case 'removeWallet':
          args.signature = await requestSignature(buildApiWalletSignature([
            'removeWallet',
            args.walletScholar,
            walletAddress,
            getWalletApplicationId(),
            timestamp,
          ]));
          args.signatureTimestamp = timestamp;
          break;
      }
    } catch (err) {
      throw new UserRejectionError();
    }

    let response = await (anonymous ? axios : api).post(process.env.REACT_APP_API_HOST + '?action=' + action, args);

    if (polling) {
      await pollingFn(polling);
    }

    try {
      if (then) await then(response.data);
    } finally {
      if (finish) await finish(0, response.data); 
    }

    return response.data;
  } catch (err) {
    if (err instanceof UserRejectionError) {
      if (finish) await finish(2, null);
    } else {
      try {
        if (error) await error(err);
      } finally {
        if (finish) await finish(1, err);
      }
    }

    if (customErrorHandler) {
      throw err;
    } else {

    }
  }
}

export async function checkTxHashPolling(txHash) {
  return await pollingFn(async () => {
    let response = await callApi({
      action: 'checkTxHash',
      args: {
        txHash,
      }
    })

    return response.valid;
  });
}