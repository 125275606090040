import axios from "axios"
import ModalPopup from "components/ModalPopup/ModalPopup"
import useActiveWeb3React from "hooks/useActiveWeb3React"
import useAuth from "hooks/useAuth"
import React, { useEffect, useState } from "react"
import { BsQuestionCircleFill } from "react-icons/bs"
import addressParse from "utils/addressParse"
import { callApi, loginToWallet, setLoginToWalletCallback } from "utils/api"
import { enableScroll } from "utils/scrollBody"
import { PopUpConnectWrapper } from "./ConnectWalletButtonStyled"
import ItemWalletConnect from "./ItemWalletConnect"

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export default function ConnectWalletPopup({show, onClose, onConnect}) {
  const { active, account: accountWeb3 } = useActiveWeb3React();
  const { login, logout } = useAuth();
  const [ step, setStep ] = useState(1);
  const [ token, setToken ] = useState("");

  const [ loginPressed, setLoginPressed ] = useState(false);

  const [ masters, setMasters ] = useState([]);
  const [ scholars, setScholars ] = useState([]);

  async function loginCallback({ token }) {
    const urlParams = new URLSearchParams(window.location.search);

    if (token) {
      try {
        let scholarResponse = await axios.post(process.env.REACT_APP_API_HOST + '?action=getScholar', {}, {
          headers: {
            Authorization: 'Bearer ' + token,
          }
        });

        let scholarList = scholarResponse.data.filter(x => x.walletAddress != x.walletScholar && x.isPlayable);

        if (step == 1 && scholarList.length > 0) {
          setToken(token);
          setStep(2);
          setMasters(scholarList.filter(x => x.isOperator));
          setScholars(scholarList.filter(x => !x.isOperator));
          return;
        } else {
          if (window.location.pathname == '/connect') {
            let redirect = urlParams.get('r') || window.document.referrer;
            var redirectUrl = new URL(redirect);
            redirectUrl.searchParams.append('token', token);
            window.location.href = redirectUrl.toString();  
          } else {
            window.localStorage.setItem('CPCWALLET_ACCESS_TOKEN', token);
            window.localStorage.setItem('CPCWALLET_APPLICATION_ID', '1'); // TODO: Hardcode
            window.location.reload();      
          }
        }
      } catch (err) {
        console.error(err);
        window.alert("Cannot fetch scholarship");
      }
    }

    enableScroll();
    onConnect();
  }
  
  const connect = async (connector: ConnectorNames) => {
    // console.log("CONNECT")
    logout();
    login(ConnectorNames[connector]);
    setLoginPressed(true);
  };

  useEffect(() => {
    // console.log(loginPressed, active, accountWeb3);
    if (loginPressed && accountWeb3) {
      setLoginPressed(false);
      setLoginToWalletCallback(loginCallback)
      loginToWallet(accountWeb3, accountWeb3);
    }
  }, [loginPressed, accountWeb3]);

  const loginScholar = async (scholarAddress) => {
    setLoginToWalletCallback(loginCallback)
    loginToWallet(accountWeb3, scholarAddress);
  }
  
  return (
    <ModalPopup
      title={'Connect to a wallet'}
      show={show}
      paddingTitle="10rem"
      onClose={onClose}
    >
      {step == 1 &&
        <PopUpConnectWrapper>
          <div className="container_connect">
            <ItemWalletConnect
              text={'Metamask'}
              image={'/image/wallet/metamask.svg'}
              onClick={() => connect(ConnectorNames.Injected)}
            />
            <ItemWalletConnect
              onClick={() => connect(ConnectorNames.Injected)}
              text={'TrustWallet'}
              image={'/image/wallet/trustWallet.svg'}
            />
            <ItemWalletConnect
              onClick={() => connect(ConnectorNames.Injected)}
              text={'MathWallet'}
              image={'/image/wallet/mathWallet.svg'}
            />
            <ItemWalletConnect
              onClick={() => connect(ConnectorNames.WalletConnect)}
              text={'TokenPocket'}
              image={'/image/wallet/tokenPocket.svg'}
            />
            <ItemWalletConnect
              onClick={() => connect(ConnectorNames.WalletConnect)}
              text={'Wallet Connect'}
              image={'/image/wallet/walletConnect.svg'}
            />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://docs.pancakeswap.finance/get-started/connection-guide`}
            >
              <div className="learn_connect">
                <BsQuestionCircleFill />
                <span className="text_inside">Learn how to connect</span>
              </div>
            </a>
          </div>
        </PopUpConnectWrapper>
      }

      {step == 2 &&
        <PopUpConnectWrapper>
          <div className="container_connect">
            <div className="scholar_section">
              <ItemWalletConnect
                text={"My wallet (" + addressParse(accountWeb3, 4) + ")"}
                image={'/image/wallet/metamask.svg'}
                onClick={() => loginScholar(accountWeb3)}
              />
            </div>

            {masters.length > 0 &&
              <div className="scholar_section">
                <div className="scholar_title">My Masters</div>

                {masters.map(scholar => (
                  <ItemWalletConnect
                    text={addressParse(scholar.walletScholar, 4)}
                    image={'/image/logo/cat_face.svg'}
                    onClick={() => loginScholar(scholar.walletScholar)}
                    key={scholar.walletScholar}
                  />
                ))}
              </div>
            }

            {scholars.length > 0 &&
              <div className="scholar_section">
                <div className="scholar_title">My Scholarships</div>

                {scholars.map(scholar => (
                  <ItemWalletConnect
                    text={addressParse(scholar.walletScholar, 4)}
                    image={'/image/logo/cat_face.svg'}
                    onClick={() => loginScholar(scholar.walletScholar)}
                    key={scholar.walletScholar}
                  />
                ))}
              </div>
            }
          </div>
        </PopUpConnectWrapper>
      }
    </ModalPopup>
  )
}