import Web3 from "web3";
import web3 from "../hooks/web3";
import TokenManagerABI from "./abi/TokenManager.json";
import CopygameLeaderABI from "./abi/CopygameLeader.json";
import BigNumber from "bignumber.js";

export let TOKEN_DEPOSITER_ADDRESS = process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT;

export default class TokenDepositer {
  contract: any
  copygameContract: any;
  walletAddressPlayer: any
  walletAddress: any
  isLeader: boolean
  
  constructor(walletAddressPlayer = "", walletAddress = "") {
    this.contract = new web3.eth.Contract(TokenManagerABI as any, TOKEN_DEPOSITER_ADDRESS)
    this.walletAddressPlayer = walletAddressPlayer;
    this.walletAddress = walletAddress;
    this.isLeader = walletAddressPlayer != walletAddress;

    this.copygameContract = new web3.eth.Contract(CopygameLeaderABI as any, walletAddress);
  }

  async depositERC20(token, amount, message = "0x") {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC20(token, amount, message).send({from: this.walletAddressPlayer});
    } else {
      const functionSignature = web3.eth.abi.encodeFunctionCall(
        {
          "inputs": [
            {
              "internalType": "contract IERC20",
              "name": "token",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "note",
              "type": "bytes"
            }
          ],
          "name": "depositERC20",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        [token, amount, message],
      );

      return await this.copygameContract.methods.execute(TOKEN_DEPOSITER_ADDRESS, 0, functionSignature).send({from: this.walletAddressPlayer, value: 0})
    }
  }

  async depositERC721(token, tokenId, data = "", message = "0x") {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC721(token, tokenId, data, message).send({from: this.walletAddressPlayer});
    } else {
      const functionSignature = web3.eth.abi.encodeFunctionCall(
        {
          "inputs": [
            {
              "internalType": "contract IERC721",
              "name": "token",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            },
            {
              "internalType": "bytes",
              "name": "note",
              "type": "bytes"
            }
          ],
          "name": "depositERC721",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        [token, tokenId, data, message],
      );

      return await this.copygameContract.methods.execute(TOKEN_DEPOSITER_ADDRESS, 0, functionSignature).send({from: this.walletAddressPlayer, value: 0})
    }
  }

  async depositERC1155(token, tokenId, amount, data = "", message = "0x") {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC1155(token, tokenId, amount, data, message).send({from: this.walletAddressPlayer});
    } else {
      const functionSignature = web3.eth.abi.encodeFunctionCall(
        {
          "inputs": [
            {
              "internalType": "contract IERC1155",
              "name": "token",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            },
            {
              "internalType": "bytes",
              "name": "note",
              "type": "bytes"
            }
          ],
          "name": "depositERC1155",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        [token, tokenId, amount, data, message],
      );

      return await this.copygameContract.methods.execute(TOKEN_DEPOSITER_ADDRESS, 0, functionSignature).send({from: this.walletAddressPlayer, value: 0})
    }
  }

  async executeMetaTransaction(functionSignature, r, s, v) {
    console.log(process.env.REACT_APP_TOKEN_MANAGER_OPERATOR);
    if (!this.isLeader) {
      return await this.contract.methods.executeMetaTransaction(
        process.env.REACT_APP_TOKEN_MANAGER_OPERATOR,
        functionSignature,
        r,
        s,
        v,
      ).send({from: this.walletAddressPlayer});
    } else {
      const functionSignature2 = web3.eth.abi.encodeFunctionCall(
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "userAddress",
              "type": "address"
            },
            {
              "internalType": "bytes",
              "name": "functionSignature",
              "type": "bytes"
            },
            {
              "internalType": "bytes32",
              "name": "sigR",
              "type": "bytes32"
            },
            {
              "internalType": "bytes32",
              "name": "sigS",
              "type": "bytes32"
            },
            {
              "internalType": "uint8",
              "name": "sigV",
              "type": "uint8"
            }
          ],
          "name": "executeMetaTransaction",
          "outputs": [
            {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        [
          process.env.REACT_APP_TOKEN_MANAGER_OPERATOR,
          functionSignature,
          r,
          s,
          v,
        ],
      );

      return await this.copygameContract.methods.execute(TOKEN_DEPOSITER_ADDRESS, 0, functionSignature2).send({from: this.walletAddressPlayer, value: 0})
    }
  }

  async approveForMaster(tokenAddress, to = TOKEN_DEPOSITER_ADDRESS) {
    // let tokens = await this.copygameContract.methods.getTokens().call();
    // let index = tokens.findIndex(x => x.toLowerCase() == tokenAddress.toLowerCase());

    // if (index == -1) {
    //   await this.copygameContract.methods.addToken(tokenAddress).send({from: this.walletAddressPlayer});
    //   index = tokens.length;
    // }

    await this.copygameContract.methods.pluginRequestAllowance(tokenAddress, to, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({from: this.walletAddressPlayer});
  }
}