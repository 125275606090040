import { colors } from 'colors/colors';
import styled from 'styled-components';

export const InputTextWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 1.8rem;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  input::placeholder {
    color: white;
  }
  input {
    transition: all 0.5s;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    background-color: ${colors.PURPLE_DARK};
    border: 0.2rem solid ${colors.PURPLE_BORDER};
    color: ${colors.PURPLE_LIGHT};
  }
  input:hover {
    border: 0.2rem solid ${colors.PURPLE_LIGHT};
  }
  input:focus {
    border: 0.2rem solid ${colors.PURPLE_LIGHT};
    color: ${colors.PURPLE_LIGHT};
  }
  input:not(:placeholder-shown) {
    border: 0.2rem solid ${colors.PURPLE_LIGHT};
  }
  .max_length_place {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    span {
      font-size: 1.8rem;
      color: ${colors.RED_PROFIT};
    }
  }
`;
