import BigNumber from "bignumber.js";

/**
 * rounds a number up or down to specified accuracy
 * @param number {numeric} number to perform operations on
 * @param precision {numeric} number of significant figures to return
 * @param direction {string} wether to round up or down
 */
function toPrecision(number, precision, direction) {
  precision -= Math.floor(number).toString().length;
  var order = Math.pow(10, precision);
  number *= order;
  var option = (direction === 'down' ? 'floor' : 'ceil');
  return (Math[option].call(null, number) / order);
}

export function removeScientificNotation(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10,e-1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2, 4);
    } else {
      x = toPrecision(x, 4, 'down');
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join('0');
    }
  }
  return x;
}

export function formatNumber4(number) {
  number = parseFloat(number);
  if (number < 1) {
    return removeScientificNotation(number)
  } else if (number < 1000) {
    return toPrecision(number, 4, 'down')
  } else {
    return Math.floor(number).toFixed(0)
  }
}