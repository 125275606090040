import { GridColumn, GridRow, TextH2, Text, FlexRow } from "components/box";
import LayoutContent from "components/Layout/LayoutContent";
import React, { useCallback, useEffect, useState } from "react";
import { TokenListWrapper, WalletWrapper } from "./styled";
import Image from "components/Image";
import Button from "components/Button";
import NftItem from "./component/NftItem";
import ModalPopup from "components/ModalPopup/ModalPopup";
import Transaction from "components/Transaction";
import { useWeb3React } from "@web3-react/core";
import useAccount from "hooks/useAccount";
import { fetchTokens } from "utils/fetchTokens";
import AmountPopup, { ConfirmPopup, InputPopup } from "components/AmountPopup/AmountPopup";
import TokenDepositer from "contracts/TokenDepositer";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { fromWei, toWei } from "utils/unitConversion";
import { pollingFn } from "utils/polling";
import { callApi, checkTxHashPolling, recoverTxWithdrawSignature } from "utils/api";
import { formatNumber4 } from "utils/formatNumber";
import CopycatToken from "contracts/CopycatToken";
import addressParse from "utils/addressParse";

const ScholarList = ({ scholar, scholarType, walletAddressPlayer, walletAddress, refreshData }) => {
  const [processing, setProcessing] = useState(false);
  const [processingTxHash, setProcessingTxHash] = useState("");

  const useTransaction = (
    <ModalPopup
      tone="blue"
      title={"Transaction"}
      show={processing}
      onClose={() => {
        setProcessing(false);
      }}
    >
      <Transaction top={"success"} bottom={<TextH2>loading</TextH2>} />
    </ModalPopup>
  );

  const [ showCommissionPopup, setShowCommissionPopup ] = useState(false);
  const [ showRemoveScholarPopup, setShowRemoveScholarPopup ] = useState(false);

  const updateCommission = useCallback(async (amount) => {
    try {
      setProcessing(true);

      if (scholarType == "MASTER") {
        await callApi({
          action: 'addMaster',
          args: {
            walletScholar: scholar.walletAddress,
            scholarPercent: parseFloat(amount) / 100,
          }
        })
      } else {
        await callApi({
          action: 'addWallet',
          args: {
            walletScholar: scholar.walletAddress,
            scholarPercent: parseFloat(amount) / 100,
          }
        })
      }
      
    } finally {
      setProcessing(false);
      setProcessingTxHash("");
      refreshData();
    }
  }, [])

  const removeScholar = useCallback(async () => {
    try {
      setProcessing(true);

      await callApi({
        action: 'removeWallet',
        args: {
          walletScholar: scholar.walletAddress,
        }
      })
      
    } finally {
      setProcessing(false);
      setProcessingTxHash("");
      refreshData();
    }
  }, [])

  return (
    <TokenListWrapper>
      {useTransaction}

      <InputPopup
        title="Update Commission"
        show={showCommissionPopup}
        label="Enter commission (%)"
        onClose={() => setShowCommissionPopup(false)}
        onSubmit={updateCommission}
        actionLabel="Update"
      ></InputPopup>

      <ConfirmPopup
        title="Remove scholarship"
        show={showRemoveScholarPopup}
        label={"Confirm removing " + addressParse(scholar.walletAddress) + " from your scholarship!"}
        onClose={() => setShowRemoveScholarPopup(false)}
        onSubmit={removeScholar}
        actionLabel="Remove"
      ></ConfirmPopup>

      <FlexRow className="token_name">
        {/* <Image
          className="token"
          src={`/image/token/${token}.svg`}
          alt="copycat"
        /> */}
        <Text>{scholarType == 'SCHOLAR' ? addressParse(scholar.walletAddress) : addressParse(scholar.walletScholar)}</Text>
      </FlexRow>
      <Text>{(parseFloat(scholar.scholarPercent) * 100).toFixed(2)}%</Text>
      {scholarType != "PLAYED_FOR" &&
        <Button
          tone="blue"
          className="btn_token deposit"
          text="Update Commission"
          disabled={false}
          onClick={() => setShowCommissionPopup(true)}
          style={{
            gridColumn: scholarType == "SCHOLAR" ? '3' : '3 / span 2',
          }}
        />
      }
      {scholarType == "SCHOLAR" &&
        <Button
          tone="pink"
          className="btn_token withdraw"
          text="Remove"
          disabled={false}
          onClick={() => setShowRemoveScholarPopup(true)}
        />
      }
    </TokenListWrapper>
  );
};

function Scholarship() {
  const { account: walletAddressPlayer } = useActiveWeb3React();
  const walletAddress = useAccount();
  const [scholars, setScholars] = useState<any[]>([]);
  const [showAddScholarPopup, setShowAddScholarPopup] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [processingTxHash, setProcessingTxHash] = useState("");

  const useTransaction = (
    <ModalPopup
      tone="blue"
      title={"Transaction"}
      show={processing}
      onClose={() => {
        setProcessing(false);
      }}
    >
      <Transaction top={"success"} bottom={<TextH2>loading</TextH2>} />
    </ModalPopup>
  );

  const addScholar = useCallback(async (address) => {
    try {
      setProcessing(true);

      await callApi({
        action: 'addWallet',
        args: {
          walletScholar: address,
          scholarPercent: 0,
        }
      })
      
    } finally {
      setProcessing(false);
      setProcessingTxHash("");
      refreshData();
    }
  }, [])

  const refreshData = useCallback(async () => {
    try {
      let scholarResponse = await callApi({
        action: 'getScholar',
        args: [],
      });

      let scholarList = scholarResponse.filter(x => x.walletAddress != x.walletScholar);

      setScholars(scholarList)
    } catch (err) {
      console.error(err);
      window.alert("Cannot fetch scholarship");
    }
  }, [])

  useEffect(() => {
    if (walletAddress) {
      refreshData();
    }
  }, [walletAddress]);

  // console.log(tokens)

  if (!walletAddress) {
    return (
      <LayoutContent>
        <div>Please connect wallet</div>
      </LayoutContent>
    );
  }

  return (
    <LayoutContent>
      {useTransaction}

      <WalletWrapper>
        <GridColumn className="token_container">
          <div className="content_token">
            <TextH2>Your masters</TextH2>
            <GridRow className="token_list">
              {scholars.filter(scholar => scholar.isMaster && scholar.isPlayable).map((scholar) => (
                <ScholarList
                  key={scholar.walletAddress + ":" + scholar.walletScholar}
                  scholar={scholar}
                  scholarType="MASTER"
                  walletAddress={walletAddress}
                  walletAddressPlayer={walletAddressPlayer}
                  refreshData={refreshData}
                ></ScholarList>
              ))}
            </GridRow>
          </div>

          <div className="badge"></div>
        </GridColumn>

        <GridColumn className="token_container">
          <div className="content_token">
            <TextH2>Your scholars</TextH2>
            <GridRow className="token_list">
              {scholars.filter(scholar => scholar.isScholar).map((scholar) => (
                <ScholarList
                  key={scholar.walletAddress + ":" + scholar.walletScholar}
                  scholar={scholar}
                  scholarType="SCHOLAR"
                  walletAddress={walletAddress}
                  walletAddressPlayer={walletAddressPlayer}
                  refreshData={refreshData}
                ></ScholarList>
              ))}
            </GridRow>

            <div style={{ display: "flex", justifyContent: "center", margin: 16 }}>
              <Button
                tone="blue"
                className="btn_token deposit"
                text="Add scholar"
                disabled={false}
                onClick={() => setShowAddScholarPopup(true)}
              />
            </div>
          </div>

          <div className="badge"></div>
        </GridColumn>

        <GridColumn className="token_container">
          <div className="content_token">
            <TextH2>Played For</TextH2>
            <GridRow className="token_list">
              {scholars.filter(scholar => !scholar.isMaster && scholar.isPlayable).map((scholar) => (
                <ScholarList
                  key={scholar.walletAddress + ":" + scholar.walletScholar}
                  scholar={scholar}
                  scholarType="PLAYED_FOR"
                  walletAddress={walletAddress}
                  walletAddressPlayer={walletAddressPlayer}
                  refreshData={refreshData}
                ></ScholarList>
              ))}
            </GridRow>
          </div>

          <div className="badge"></div>
        </GridColumn>
      </WalletWrapper>

      <InputPopup
        title="Add scholarship"
        show={showAddScholarPopup}
        label="Enter wallet address"
        onClose={() => setShowAddScholarPopup(false)}
        onSubmit={addScholar}
        actionLabel="Add"
        type="text"
      ></InputPopup>
    </LayoutContent>
  );
}

export default Scholarship;
