import CopycatToken from "contracts/CopycatToken";
import web3 from "hooks/web3";
import { callApi, getWalletAddress } from "./api";
import { fromWei } from "./unitConversion";

export async function fetchTokens() {
  // Get account
  const account = (await web3.eth.getAccounts())[0];
  const walletAddress = getWalletAddress(account);

  const tokens = await callApi({
    action: 'getBalance',
    args: {
      tokenType: 'ERC20',
      forApplicationId: parseInt(window.localStorage.getItem('CPCWALLET_APPLICATION_ID') || "1"),
    }
  });

  // console.log(tokens)

  if (account) {
    for (let i = 0; i < tokens.length; i++) {
      try {
        tokens[i].walletBalance = await new CopycatToken(account, tokens[i].tokenAddress).balanceOf(walletAddress);
        // tokens[i].walletBalance = 0;
      } catch (err) {
        console.error(err);
      }
    }
  }

  console.log(tokens)

  return tokens;
}