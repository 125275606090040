import Layout from 'components/Layout';
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import GlobalStyle from 'styles/global';
import Wallet from './feature/Wallet';
import Scholarship from './feature/Scholarship';
import ScrollToTop from 'context/ScrollToTop';
import history from 'routerHistory';
import ConnectWalletPage from 'components/ConnectWalletButton/ConnectWalletPage';
// import { SocketContext, socket } from './context/socket';

const App = () => {
  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <GlobalStyle />
        <Layout>
          <Switch>
            <Route path="/" exact component={Wallet} />
            <Route path="/scholarship" exact component={Scholarship} />
            <Route path="/connect" exact component={ConnectWalletPage} />
          </Switch>
        </Layout>
      </Router>
    </>
  );
};

export default App;
