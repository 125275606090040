import { GridColumn, GridRow, TextH2, Text, FlexRow } from "components/box";
import LayoutContent from "components/Layout/LayoutContent";
import React, { useCallback, useEffect, useState } from "react";
import { TokenListWrapper, WalletWrapper } from "./styled";
import Image from "components/Image";
import Button from "components/Button";
import NftItem from "./component/NftItem";
import ModalPopup from "components/ModalPopup/ModalPopup";
import Transaction from "components/Transaction";
import { useWeb3React } from "@web3-react/core";
import useAccount from "hooks/useAccount";
import { fetchTokens } from "utils/fetchTokens";
import AmountPopup from "components/AmountPopup/AmountPopup";
import TokenDepositer from "contracts/TokenDepositer";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { fromWei, toWei } from "utils/unitConversion";
import { pollingFn } from "utils/polling";
import { callApi, checkTxHashPolling, recoverTxWithdrawSignature } from "utils/api";
import { formatNumber4 } from "utils/formatNumber";
import CopycatToken from "contracts/CopycatToken";

const TokenList = ({ token, name, symbol, decimals, balance, locked, walletBalance, walletAddressPlayer, walletAddress, refreshData }) => {
  const [processing, setProcessing] = useState(false);
  const [processingTxHash, setProcessingTxHash] = useState("");

  const useTransaction = (
    <ModalPopup
      tone="blue"
      title={"Transaction"}
      show={processing}
      onClose={() => {
        setProcessing(false);
      }}
    >
      <Transaction top={"success"} bottom={<TextH2>loading</TextH2>} />
    </ModalPopup>
  );

  const [ showDepositPopup, setShowDepositPopup ] = useState(false);
  const [ showWithdrawPopup, setShowWithdrawPopup ] = useState(false);

  const deposit = useCallback(async (amount) => {
    try {
      setProcessing(true);

      let tokenDepositer = new TokenDepositer(walletAddressPlayer, walletAddress);
      let tokenContract = new CopycatToken(walletAddressPlayer, token);

      let allowanceCheck = await tokenContract.checkAllowance(walletAddress, process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT, toWei(amount, decimals));

      if (!allowanceCheck) {
        if (walletAddress.toLowerCase() == walletAddressPlayer.toLowerCase()) {
          await tokenContract.approve(process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT);
        } else {
          await tokenDepositer.approveForMaster(token);
        }
      }

      let tx = await tokenDepositer.depositERC20(token, toWei(amount, decimals));

      console.log(tx)

      setProcessingTxHash(tx.transactionHash);
      await checkTxHashPolling(tx.transactionHash)
    } finally {
      setProcessing(false);
      setProcessingTxHash("");
      refreshData();
    }
  }, [])

  const withdraw = useCallback(async (amount) => {
    await callApi({
      action: 'txWithdraw',
      args: {
        tokenAddress: token,
        tokenId: 0,
        amount: toWei(amount, decimals),
      },
      before: async () => setProcessing(true),
      then: async (data) => {
        console.log("XXX")
        let meta = recoverTxWithdrawSignature(data);
        console.log(meta)
        let tx = await new TokenDepositer(walletAddressPlayer, walletAddress).executeMetaTransaction(meta.functionSignature, meta.r, meta.s, meta.v);

        console.log("YYY")

        setProcessingTxHash(tx.transactionHash);
        await checkTxHashPolling(tx.transactionHash)  
      },
      finish: async (status, data) => {
        setProcessing(false);
        setProcessingTxHash("");
        refreshData();
      }
    })
  }, [])

  return (
    <TokenListWrapper>
      {useTransaction}

      <AmountPopup
        title="Deposit"
        show={showDepositPopup}
        onClose={() => setShowDepositPopup(false)}
        onSubmit={deposit}
        tokenSymbol={symbol}
        actionLabel="Deposit"
        availableBalance={walletBalance}
      ></AmountPopup>

      <AmountPopup
        title="Withdraw"
        show={showWithdrawPopup}
        onClose={() => setShowWithdrawPopup(false)}
        onSubmit={withdraw}
        tokenSymbol={symbol}
        actionLabel="Withdraw"
        availableBalance={balance}
      ></AmountPopup>

      <FlexRow className="token_name">
        <Image
          className="token"
          src={`/image/token/${token}.svg`}
          alt="copycat"
        />
        <Text>{name.toUpperCase()}</Text>
      </FlexRow>
      <Text>{formatNumber4(balance)}</Text>
      <Button
        tone="blue"
        className="btn_token deposit"
        text="Deposit"
        disabled={false}
        onClick={() => setShowDepositPopup(true)}
      />
      <Button
        tone="pink"
        className="btn_token withdraw"
        text="Withdraw"
        disabled={false}
        onClick={() => setShowWithdrawPopup(true)}
      />
    </TokenListWrapper>
  );
};

const TokenListReward = ({ token, name, symbol, decimals, balance, locked, walletBalance, walletAddressPlayer, walletAddress, refreshData }) => {
  const [processing, setProcessing] = useState(false);
  const [processingTxHash, setProcessingTxHash] = useState("");

  const useTransaction = (
    <ModalPopup
      tone="blue"
      title={"Transaction"}
      show={processing}
      onClose={() => {
        setProcessing(false);
      }}
    >
      <Transaction top={"success"} bottom={<TextH2>loading</TextH2>} />
    </ModalPopup>
  );

  const [ showDepositPopup, setShowDepositPopup ] = useState(false);
  const [ showWithdrawPopup, setShowWithdrawPopup ] = useState(false);

  const harvest = useCallback(async () => {
    await callApi({
      action: 'txHarvestReward',
      args: {
        tokenAddress: token,
        tokenId: 0,
      },
      before: async () => setProcessing(true),
      finish: async (status, data) => {
        setProcessing(false);
        setProcessingTxHash("");
        refreshData();
      }
    })
  }, [])

  return (
    <TokenListWrapper>
      {useTransaction}

      <FlexRow className="token_name">
        <Image
          className="token"
          src={`/image/token/${token}.svg`}
          alt="copycat"
        />
        <Text>{name.toUpperCase()}</Text>
      </FlexRow>
      <Text>{balance}</Text>
      <div>&nbsp;</div>
      <Button
        tone="pink"
        className="btn_token deposit"
        text="Harvest"
        disabled={false}
        onClick={() => harvest()}
      />
    </TokenListWrapper>
  );
};

function Wallet() {
  const { account: walletAddressPlayer } = useActiveWeb3React();
  const walletAddress = useAccount();
  const [tokens, setTokens] = useState<any[]>([]);

  const refreshData = useCallback(async () => {
    setTokens(await fetchTokens());
  }, [])

  useEffect(() => {
    if (walletAddress) {
      refreshData();
    }
  }, [walletAddress]);

  // console.log(tokens)

  if (!walletAddress) {
    return (
      <LayoutContent>
        <div>Please connect wallet</div>
      </LayoutContent>
    );
  }

  return (
    <LayoutContent>
      <WalletWrapper>
        <GridColumn className="token_container">
          <div className="content_token">
            <TextH2>Your Token</TextH2>
            <GridRow className="token_list">
              {tokens.map((token) => (
                <TokenList
                  key={token.tokenAddress}
                  token={token.tokenAddress}
                  name={token.name}
                  symbol={token.symbol}
                  decimals={token.decimals}
                  balance={fromWei(token.balance, token.decimals)}
                  locked={fromWei(token.locked, token.decimals)}
                  walletBalance={fromWei(token.walletBalance, token.decimals)}
                  walletAddress={walletAddress}
                  walletAddressPlayer={walletAddressPlayer}
                  refreshData={refreshData}
                ></TokenList>
              ))}
            </GridRow>
          </div>

          <div className="badge"></div>
        </GridColumn>

        <GridColumn className="token_container">
          <div className="content_token">
            <TextH2>Your Rewards</TextH2>
            <GridRow className="token_list">
              {tokens.map((token) => parseFloat(token.pendingReward) > 0 ? (
                <TokenListReward
                  key={token.tokenAddress}
                  token={token.tokenAddress}
                  name={token.name}
                  symbol={token.symbol}
                  decimals={token.decimals}
                  balance={fromWei(token.pendingReward, token.decimals)}
                  locked={0}
                  walletBalance={fromWei(token.walletBalance, token.decimals)}
                  walletAddress={walletAddress}
                  walletAddressPlayer={walletAddressPlayer}
                  refreshData={refreshData}
                ></TokenListReward>
              ) : <></>)}
            </GridRow>
          </div>

          <div className="badge"></div>
        </GridColumn>
        {/* <GridColumn className="token_container nft_container">
          <div className="content_token">
            <TextH2>Your NFT</TextH2>
            <GridRow className="nft_list">
              <NftItem />
              <NftItem />
              <NftItem />
            </GridRow>
          </div>
          <div className="badge"></div>
        </GridColumn> */}
      </WalletWrapper>
    </LayoutContent>
  );
}

export default Wallet;
