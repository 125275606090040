import Web3 from "web3";
import web3 from "../hooks/web3";
import CopycatTokenABI from "./abi/CopycatToken.json";
import BigNumber from "bignumber.js";

export let COPYCAT_TOKEN_ADDRESS = process.env.NEXT_PUBLIC_COPYCAT_TOKEN;

export default class CopycatToken {
  contract: any
  from: any
  
  constructor(from = "", address = COPYCAT_TOKEN_ADDRESS) {
    this.contract = new web3.eth.Contract(CopycatTokenABI as any, address)
    this.from = from;
  }

  async balanceOf(address) {
    return await this.contract.methods.balanceOf(address).call()
  }

  async symbol() {
    return await this.contract.methods.symbol().call()
  }

  async approve(spender) {
    console.log("Approve Start")
    let response = await this.contract.methods.approve(spender, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({from: this.from})
    console.log("Approve end", response)
    return response;
  }

  async limitedApprove(spender, amount) {
    return await this.contract.methods.approve(spender, amount).send({from: this.from})
  }

  async allowance(owner, spender) {
    return await this.contract.methods.allowance(owner, spender).call()
  }

  async checkAllowance(owner, spender, requiredAmount) {
    let allowedAmount = await this.allowance(owner, spender);
    return new BigNumber(allowedAmount).gte(new BigNumber(requiredAmount));
  }

  async totalSupply() {
    return await this.contract.methods.totalSupply().call()
  }

  async totalBurn() {
    return await this.contract.methods.totalBurn().call()
  }

  async upgrade() {
    return await this.contract.methods.upgrade().send({from: this.from})
  }
}