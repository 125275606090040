import { GridColumn } from 'components/box';
import Image from 'components/Image';
import React from 'react';
import styled from 'styled-components/macro';

const TransactionWrapper = styled.div`
  width: 50rem;
  padding: 1rem;
  .transaction_container {
    place-items: center;
    gap: 1rem;
  }
  .loading {
    width: 10rem;
  }
`;

interface Props {
  bottom?: React.ReactChild;
  top?: 'load' | 'success' | 'error';
}

const srcCat = {
  load: '/image/game/headcat-draw.png',
  success: '/image/game/headcat-win.png',
  error: '/image/game/headcat-lose.png',
};

function Transaction({ top, bottom }: Props) {
  return (
    <TransactionWrapper>
      <GridColumn className="transaction_container">
        {top && <Image className="loading" src={srcCat[top]} alt="loading" />}
        {bottom}
      </GridColumn>
    </TransactionWrapper>
  );
}

export default Transaction;
