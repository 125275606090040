import { colors } from 'colors/colors';
import { GridColumn } from 'components/box';
import styled from 'styled-components/macro';
import { animationShadow } from 'styling/animation';
import { device, flexAlign } from 'styling/fast';

export const LayoutWrapper = styled.div`
  position: relative;
  width: 95%;
  margin: 2rem auto;
  border-radius: 2rem;
  background-color: ${colors.PURPLE_DARK_BG};
  .bg_dark {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 10rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    display: none;
  }
  .content {
    height: auto;
    min-height: calc(100vh - 4rem);
    display: grid;
    grid-template-columns: 25rem 1fr 25rem;
    gap: 1rem;
    padding-bottom: 6rem;
  }
  .content_container {
    background-color: red;
    background-color: ${colors.PURPLE_LIGHT};
    padding: 0.5rem;
    border-radius: 2rem;
    display: flex;
    .inside_border {
      padding: 2rem;
      border-radius: 2rem;
      width: 100%;
      height: 100%;
      background-color: #120338;
    }
  }
  .menu_top_container {
    background-color: ${colors.PINK2};
    width: 100%;
    height: 10rem;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    display: none;
    justify-content: space-between;
    padding: 0 2rem;
    .icon {
      width: 7rem;
      height: 7rem;
      background-color: white;
      border-radius: 1rem;
      color: ${colors.PINK2};
      font-size: 6rem;
      display: flex;
      ${flexAlign}
    }
    .icon2 {
      width: 7rem;
      height: 7rem;
      display: flex;
      ${flexAlign}
    }
  }
  @media ${device.laptop} {
    .bg_dark {
      display: block;
    }
    margin-top: 12rem;
    .content {
      min-height: calc(100vh - 14rem);
      grid-template-columns: 1fr;
    }
    .menu_top_container {
      display: flex;
    }
  }
  ${animationShadow('white', '20px', '5s')}
`;

export const FooterWrapper = styled.div`
  height: 6rem;
  width: 100%;
  padding: 1rem 0;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  border-radius: 0 0 2rem 2rem;
  left: 0;
  .copyright {
    width: 100%;
    padding: 0 15%;
    bottom: 0;
    justify-content: space-between;
  }
`;

export const LinkItemTEXT = styled.div<{ active?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 5rem 1fr;
  /* height: 6rem; */
  padding: 1.4rem 2rem;
  align-items: center;
  position: relative;
  background-color: ${({ active }) => (active ? colors.PINK1 : 'transparent')};
  .icon {
    font-size: 2.5rem;
    color: white;
    color: ${({ active }) => (active ? 'white' : colors.PINK1)};
  }
  opacity: 1;
  h1 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    @media ${device.laptop} {
      font-size: 3rem;
    }
  }
  :after {
    content: '';
    position: absolute;
    height: ${({ active }) => (active ? '80%' : '0')};
    width: 0.5rem;
    background-color: white;
    right: 0.2rem;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  :hover {
    * {
      opacity: 0.8;
    }
  }
`;

export const NavbarWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  gap: 1rem;
  position: relative;
  place-items: flex-start;
  padding: 2rem 0;
  background-color: ${colors.BG};
  border-radius: 2rem 0 0 0;
  .header {
    width: 100%;
    align-items: center;
    img {
      height: 8rem;
    }
  }
  .connect_wallet {
    width: 100%;
    position: absolute;
    bottom: 1rem;
  }
  .menu_contain {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 30rem;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 10rem;
    height: calc(100vh - 10rem);
  }
`;

export const MobileNavbar = styled(GridColumn)`
  width: 100%;
  height: 100%;
  padding: 4rem;
  position: relative;
  .menu_mobile {
    position: relative;
    z-index: 1;
  }
  .bg_opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export const SideNavWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  place-content: flex-start;
  gap: 1rem;
  width: 95%;
  height: 95%;
  background-color: ${colors.BG};
  border-radius: 2rem;
  margin: auto 0;
  padding: 1rem;
  .account_container {
    border-radius: 1rem;
    padding: 1rem;
    gap: 0.5rem;
    place-items: center;
    background-color: ${colors.BG};
  }
  .log_btn {
    height: auto;
    width: 80%;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.1rem 0;
  }
  .platform {
  }
  @media ${device.laptop} {
    width: 30rem;
    position: fixed;
    z-index: 99;
    right: 0;
    top: 10rem;
    height: calc(100vh - 10rem);
  }
`;

export const ButtonPlatform = styled.div<{ active?: boolean }>`
  background-color: white;
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: 6rem 1fr;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  background-color: ${({ active }) =>
    active ? colors.YELLOW_BINANCE : 'white'};
  img {
    height: 3rem;
  }
  h2 {
    color: ${colors.BG};
    font-size: 2rem;
    font-weight: 500;
  }
  :hover {
    background-color: ${colors.YELLOW_BINANCE};
    color: ${colors.PURPLE_DARK_BG};
  }
`;
