import InputText from "components/InputText/InputText";
import React, { useState } from "react";
import styled from "styled-components";
import { formatNumber4 } from "utils/formatNumber";
import ModalPopup from "../ModalPopup/ModalPopup";
import { imageBg, toneColor } from 'styling/fast';

const AmountPopupStyled = styled(ModalPopup)`
.action_btn {
  font-family: inherit;
  font-weight: 500;
  font-size: 2rem;
  border: 0;
  padding: 1.5rem 1.5rem;
  cursor: pointer;
  color: white;
  ${imageBg}
  /* background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%); */
  opacity: 0.8;
  margin-left: 16px;
}

.action_btn:hover {
  opacity: 1;
}
`

export default function AmountPopup({
  onSubmit,
  availableBalance,
  tokenSymbol,
  actionLabel,
  ...props
}) {
  const [amount, setAmount] = useState("");

  return (
    <AmountPopupStyled
      {...props}
      show={props.show}
      onClose={props.onClose}
      title={props.title}
    >
      <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
        <div style={{ alignSelf: "flex-end", marginBottom: 8, color: "white" }}>
          Available: {formatNumber4(availableBalance)} {tokenSymbol}
        </div>

        <div style={{ marginBottom: 16, width: "100%" }}>
          <InputText
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            showMax
            onMaxClick={() => setAmount(formatNumber4(availableBalance))}
          ></InputText>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn_token withdraw" onClick={props.onClose} style={{ marginRight: 12 }}>
            Cancel
          </button>

          <button className="btn_token deposit" onClick={() => onSubmit(parseFloat(amount) || 0)}>
            {actionLabel}
          </button>
        </div>
      </div>
    </AmountPopupStyled>
  );
}

export function InputPopup({
  onSubmit,
  label,
  actionLabel,
  type = "number",
  ...props
}) {
  const [amount, setAmount] = useState("");

  return (
    <AmountPopupStyled
      {...props}
      show={props.show}
      onClose={props.onClose}
      title={props.title}
    >
      <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
        <div style={{ marginBottom: 8, color: "white" }}>
          {label}
        </div>

        <div style={{ marginBottom: 16, width: "100%" }}>
          <InputText
            type={type}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          ></InputText>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn_token withdraw" onClick={props.onClose} style={{ marginRight: 12 }}>
            Cancel
          </button>

          <button className="btn_token deposit" onClick={() => onSubmit(type == "number" ? (parseFloat(amount) || 0) : amount)}>
            {actionLabel}
          </button>
        </div>
      </div>
    </AmountPopupStyled>
  );
}

export function ConfirmPopup({
  onSubmit,
  label,
  actionLabel,
  ...props
}) {
  return (
    <AmountPopupStyled
      {...props}
      show={props.show}
      onClose={props.onClose}
      title={props.title}
    >
      <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
        <div style={{ marginBottom: 8, color: "white" }}>
          {label}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn_token withdraw" onClick={props.onClose} style={{ marginRight: 12 }}>
            Cancel
          </button>

          <button className="btn_token deposit" onClick={() => onSubmit()}>
            {actionLabel}
          </button>
        </div>
      </div>
    </AmountPopupStyled>
  );
}
