import Image from 'components/Image';
import { avatarUserPath } from 'config';
import React from 'react';
import styled from 'styled-components/macro';

const UserAvatarWrapper = styled.div`
  height: 6rem;
  width: 6rem;
  padding: 0.2rem;
  border-radius: 100%;
  background-color: white;
`;

interface Props {
  size?: string;
  type:
    | 'blackCat'
    | 'blackCat2'
    | 'catRich'
    | 'catSwag'
    | 'fox'
    | 'oldCat'
    | 'orangeCat'
    | 'pinkCat'
    | 'pinkCat2'
    | 'whitecat';
}

function UserAvatar({ size, type }: Props) {
  return (
    <UserAvatarWrapper style={{ width: size, height: size }}>
      <Image src={avatarUserPath[type]} alt={type} />
    </UserAvatarWrapper>
  );
}

export default UserAvatar;
